<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      height="auto"
      fixed-header
      dense
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          v-if="isUpdateInfo || isUpdateProduct || isUpdateBranch"
          offset-x
          left
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <i class="feather icon-more-vertical"></i>
                </v-btn>
              </template>
              <span>Más opciones</span>
            </v-tooltip>
          </template>

          <!-- @click="getPatient(item)" -->
          <v-list dense nav v-if="isUpdateInfo">
            <v-list-item @click="update(item, 5)">
              <v-list-item-content>
                <v-list-item-title>
                  <i class="feather icon-file-text mr-1"></i> Editar
                  información</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="isUpdateProduct">
            <v-list-item @click="update(item, 3)">
              <v-list-item-content>
                <v-list-item-title
                  ><i class="feather icon-list mr-2"></i>Editar
                  productos</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="isUpdateBranch">
            <v-list-item @click="update(item, 4)">
              <v-list-item-content>
                <v-list-item-title
                  ><i class="feather icon-truck mr-2"></i>Editar
                  sucursales</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <!--  <v-tooltip top v-if="isUpdateInfo">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="update(item, 5)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-file-text"></i>
            </span>
          </template>
          <span>Editar información del cuadro de distribución.</span>
        </v-tooltip>
        <v-tooltip top v-if="isUpdateProduct">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="update(item, 3)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-list"></i>
            </span>
          </template>
          <span>Editar productos del cuadro de distribución.</span>
        </v-tooltip>
        <v-tooltip top v-if="isUpdateBranch">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="update(item, 4)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-folder"></i>
            </span>
          </template>
          <span>Editar sucursales del cuadro de distribución.</span>
        </v-tooltip> -->
        <div v-if="!isUpdateProduct && !isUpdateBranch && !isUpdateInfo">-</div>
      </template>
    </v-data-table>
    <ButtonPrintComponent v-if="true" />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  props: {
    headers: {},
    items: {},
    isUpdateProduct: false,
    isUpdateBranch: false,
    isUpdateInfo: false,
    update: Function,
  },
  data() {
    return {
      search: "",
    };
  },
  components: {
    ButtonPrintComponent
  },
  methods: {},
};
</script>

<style scoped>
.v-list--nav {
  padding-left: 8px !important;
  padding-right: 5px !important;
}
.v-list {
  padding: 0px 0px 0px 0px !important;
}
</style>