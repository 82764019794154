<template>
  <div>
    <div class="row">
      <div class="col-12 pt-10 pb-0">
        <h6>
          <v-icon v-if="!model.idDistributionBoard" class="pb-1" v-text="'mdi-numeric-3-box-outline'" />
          <v-icon v-else class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
          Sucursales del cuadro de distribución <span  v-if="!model.idDistributionBoard == ''"> | {{this.model.name}}  </span>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="100"
          transition="fade-transition"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="idBranchOffice"
            height="auto"
            dense
            v-model="model.branchOffices"
            fixed-header
            hide-selected
            :single-select="false"
            :search="searchBranch"
            :footer-props="{
              'items-per-page-text': $t('table.item_table'),
              'items-per-page-all-text': $t('table.all'),
              'items-per-page-options': [-1],
            }"
            :loading-text="$t('table.loading')"
            :no-data-text="$t('table.no_results_available')"
            :no-results-text="$t('table.no_results_search')"
            :show-select="true"
          >
            <template v-slot:top>
              <div class="row pt-0">
                <div class="col-md-6 pt-0"></div>
                <div class="col-md-6 pt-0">
                  <v-text-field
                    v-model="searchBranch"
                    :label="$t('table.search')"
                    class="pl-1"
                  ></v-text-field>
                </div>
              </div>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-lazy>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {},
    items: {},
    model: Object
  },
  data() {
    return {
      searchBranch: "",
      loading: true,
    };
  },
};
</script>