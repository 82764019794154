var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"height":"auto","fixed-header":"","dense":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isUpdateInfo || _vm.isUpdateProduct || _vm.isUpdateBranch)?_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var menu = ref.on;
    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"feather icon-more-vertical"})])]}}],null,true)},[_c('span',[_vm._v("Más opciones")])])]}}],null,true)},[(_vm.isUpdateInfo)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.update(item, 5)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-file-text mr-1"}),_vm._v(" Editar información")])],1)],1)],1):_vm._e(),(_vm.isUpdateProduct)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.update(item, 3)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-list mr-2"}),_vm._v("Editar productos")])],1)],1)],1):_vm._e(),(_vm.isUpdateBranch)?_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.update(item, 4)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-truck mr-2"}),_vm._v("Editar sucursales")])],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.isUpdateProduct && !_vm.isUpdateBranch && !_vm.isUpdateInfo)?_c('div',[_vm._v("-")]):_vm._e()]}}],null,true)}),(true)?_c('ButtonPrintComponent'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }