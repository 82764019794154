<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">Catálogos</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/dashboard">Inicio</a></li>
                <li class="breadcrumb-item">
                  <a @click="changeAction(1)">Catálogos</a>
                </li>
                <li class="breadcrumb-item">{{ title }}</li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.DIS_BOA_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i> Crear cuadro distribución
            </button>
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(2) || showAction(3) || showAction(4) || showAction(5)
              "
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-1"></i> Regresar
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i
                        class="fa fa-check-square-o mr-2"
                        aria-hidden="true"
                      ></i>
                      {{ title }}
                    </h5>
                  </div>
                </div>
              </div>
              <DistributionTableComponent
                :headers="headers"
                v-show="showAction(1)"
                :items="items"
                :isUpdateProduct="
                  withPermissionName(permissions.DIS_BOA_PROD_EDIT_03)
                "
                :isUpdateBranch="
                  withPermissionName(permissions.DIS_BOA_BRAN_EDIT_04)
                "
                :isUpdateInfo="withPermissionName(permissions.DIS_BOA_EDIT_02)"
                :update="update"
              />
              <div class="card-body" v-if="showAction(2)">
                <DistributionFormComponent v-bind:model="model" />
                <DistributionProductsFormComponent
                  :headers="headerProduct"
                  :items="listProduts"
                  v-bind:model="model"
                />
                <DistributionBranchFormComponent
                  :headers="headersBranch"
                  :items="branchOffices"
                  v-bind:model="model"
                />
                <ButtonActionComponent
                  v-on:onClick="click"
                  :buttonTitle="buttonTitle"
                  :validator="validator"
                  :sending="sending"
                />
              </div>
              <div class="card-body" v-if="showAction(4)">
                <DistributionBranchFormComponent
                  :headers="headersBranch"
                  :items="branchOffices"
                  v-bind:model="model"
                />
                <ButtonActionComponent
                  v-on:onClick="click"
                  :buttonTitle="buttonTitle"
                  :validator="validator"
                  :sending="sending"
                />
              </div>
              <div class="card-body" v-show="showAction(3)">
                <DistributionProductsFormComponent
                  :headers="headerProduct"
                  :items="listProduts"
                  v-bind:model="model"
                />
                <ButtonActionComponent
                  v-on:onClick="click"
                  :buttonTitle="buttonTitle"
                  :validator="validator"
                  :sending="sending"
                />
              </div>
              <div class="card-body" v-show="showAction(5)">
                <DistributionFormComponent v-bind:model="model" />
                <ButtonActionComponent
                  v-on:onClick="click"
                  :buttonTitle="buttonTitle"
                  :validator="validator"
                  :sending="sending"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonActionComponent from "@/components/distribution/ButtonActionComponent";
import DistributionTableComponent from "@/components/distribution/DistributionTableComponent";
import DistributionBranchFormComponent from "@/components/distribution/DistributionBranchFormComponent";
import DistributionProductsFormComponent from "@/components/distribution/DistributionProductsFormComponent";
import DistributionFormComponent from "@/components/distribution/DistributionFormComponent";
import {
  DISTRIBUTION_BOARD,
  BRANCH_OFFICE_DISTRIBUTION_BOARD,
  PRODUCTS_DISTRIBUTION_BOARD,
} from "@/core/DataTableHeadersLocale";
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import { SwalConfirm } from "@/core/SwalAlert";
import {
  DistributionRequest,
  BranchOfficeRequest,
  ProductsRequest,
} from "@/core/request";

export default {
  data() {
    return {
      action: 1,
      headers: DISTRIBUTION_BOARD(),
      headersBranch: BRANCH_OFFICE_DISTRIBUTION_BOARD(),
      headerProduct: PRODUCTS_DISTRIBUTION_BOARD(),
      items: [],
      branchOffice: null,
      originId: "",
      loading: null,
      branchOffices: [],
      listProduts: [],
      /* Información de cuadro de districión */
      model: {
        name: "",
        description: "",
        idDistributionBoard: "",
        products: [],
        branchOffices: [],
      },
      sending: false,
      /* Permisos */
      permissions: PermissionConstants,
    };
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    validator() {
      if (
        !(
          this.model.name.trim() === "" ||
          this.model.description.trim() === "" ||
          this.model.description.length > 200 ||
          this.model.name.length > 100
        )
      ) {
        return false;
      }
      return true;
    },
    buttonTitle() {
      return this.action == 2
        ? "Registrar cuadro de distribución"
        : this.action == 3
        ? "Modificar productos"
        : this.action == 4
        ? "Modificar sucursales"
        : this.action == 5
        ? "Modificar información"
        : "";
    },
    title() {
      return this.action == 1
        ? "Cuadro de distribución"
        : this.action == 2
        ? "Registrar cuadro de distribución"
        : this.action == 3
        ? "Modificar productos del cuadro de distribución"
        : this.action == 4
        ? "Modificar sucursales del cuadro de distribución"
        : this.action == 5
        ? "Modificar información del cuadro de distribución"
        : "";
    },
  },
  methods: {
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    showAction(action) {
      return this.action == action;
    },
    updateOrigin(data) {
      this.originId = data;
    },
    clear() {
      this.model = {
        name: "",
        description: "",
        idDistributionBoard: "",
        products: [],
        branchOffices: [],
      };
    },
    async productsClear() {
      return this.model.products.map((object) => {
        return {
          idProduct: object.idProduct,
        };
      });
    },
    async branchOfficesClear() {
      return this.model.branchOffices.map((object) => {
        return {
          idBranchOffice: object.idBranchOffice,
        };
      });
    },
    async createBoardDistribution() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información será registrada.",
      });
      if (!isConfirmed) {
        return;
      }
      let products = [];
      let branchOffices = [];
      products = await this.productsClear();
      branchOffices = await this.branchOfficesClear();
      this.loading = UtilFront.getLoadding();
      DistributionRequest.createBoardDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        name: this.model.name,
        description: this.model.description,
        products: products,
        branchOffices: branchOffices,
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Add_Success_0001");
            this.loading.hide();
            this.changeAction(1);
            this.listDistributionBoard();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    changeAction(action) {
      this.action = action;
      action == 1 ? this.clear() : this.action == 2 ? null : null;
      if(action == 2 && this.listProduts.length == 0 && this.branchOffices.length == 0) {
        this.listAllProduts();
      }
      this.updateAction(action);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    getBranchOfficeDistribution(item, action) {
      this.loading = UtilFront.getLoadding();
      DistributionRequest.getBranchOfficeDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        idDistributionBoard: item.idDistributionBoard,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.model.branchOffices = data.branchOffices;
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    getProductsDistribution(item, action) {
      this.loading = UtilFront.getLoadding();
      DistributionRequest.getProductsDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        idDistributionBoard: item.idDistributionBoard,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.model.products = data.products;
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    update(item, action) {
      this.model.name = item.name;
      this.model.description = item.description;
      this.model.idDistributionBoard = item.idDistributionBoard;
      switch (action) {
        case 5:
          this.action = action;
          break;
        case 4:
          this.getBranchOfficeDistribution(item, action);
          break;
        case 3:
          this.getProductsDistribution(item, action);
          break;
        default:
          break;
      }
    },
    /* Moficacion de información de la tabla de distribución */
    async updateInfoBoardDistribution() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      DistributionRequest.updateBoardDistributionInfo({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        name: this.model.name,
        description: this.model.description,
        idDistributionBoard: this.model.idDistributionBoard,
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Update_Success_0001");
            this.loading.hide();
            this.changeAction(1);
            this.listDistributionBoard();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    /* Modificación de las lista de los productos de la distribución */
    async updateProductBoardDistribution() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      let products = [];
      products = await this.productsClear();
      this.loading = UtilFront.getLoadding();
      DistributionRequest.updateProductBoardDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        idDistributionBoard: this.model.idDistributionBoard,
        products: products,
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Update_Success_0001");
            this.loading.hide();
            this.changeAction(1);
            this.listDistributionBoard();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
        });
    },
    /* Modificación de sucursales en la tabla de distribución */
    async updateBranchOfficeBoardDistribution() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      let branchOffices = [];
      branchOffices = await this.branchOfficesClear();
      this.loading = UtilFront.getLoadding();
      DistributionRequest.updateBranchOfficeBoardDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        idDistributionBoard: this.model.idDistributionBoard,
        branchOffices: branchOffices,
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Update_Success_0001");
            this.loading.hide();
            this.changeAction(1);
            this.listDistributionBoard();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
        });
    },
    click() {
      this.sending = true;
      switch (this.action) {
        case 1:
          break;
        case 2:
          if (
            !(
              this.model.name.trim() === "" ||
              this.model.description.trim() === ""
            )
          ) {
            if (
              this.model.products.length > 0 &&
              this.model.branchOffices.length > 0
            ) {
              if (this.model.products.length <= 500) {
                this.createBoardDistribution();
              } else {
                this.alert("error", "Error_Distribution_Product_0001");
              }
            } else {
              this.alert("error", "Error_Distribution_0001");
            }
          } else {
            this.alert("error", "Error_Distribution_0002");
          }
          break;
        case 3:
          if (this.model.products.length > 0) {
            if (this.model.products.length <= 500) {
              this.updateProductBoardDistribution();
            } else {
              this.alert("error", "Error_Distribution_Product_0001");
            }
          } else {
            this.alert("error", "Error_Distribution_Product_0002");
          }
          break;
        case 4:
          if (this.model.branchOffices.length > 0) {
            this.updateBranchOfficeBoardDistribution();
          } else {
            this.alert("error", "Error_Distribution_Branch_0001");
          }
          break;
        case 5:
          if (
            !(
              this.model.name.trim() === "" ||
              this.model.description.trim() === ""
            )
          ) {
            this.updateInfoBoardDistribution();
          } else {
            this.alert("error", "Error_Distribution_0002");
          }
          break;
        default:
          break;
      }
      this.sending = false;
    },
    listDistributionBoard() {
      this.loading = UtilFront.getLoadding();
      DistributionRequest.listDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
        branchOffice: {
          idBranchOffice: this.originId,
        },
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.items = data;
          } else {
            this.items = [];
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async listAllBranchOffices() {
      await BranchOfficeRequest.listAllActive()
        .then((response) => {
          let { success, data } = response.data.data;
          if (success) {
            this.branchOffices = data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error);
        })
        .finally(() => {
        });
    },
    async listAllProduts() {
      this.loading = UtilFront.getLoadding();
      await ProductsRequest.listAll()
        .then((response) => {
          let { data } = response.data.data;
          if (data) {
          this.listProduts = data;
          this.listAllBranchOffices();
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
  async created() {
    this.updateOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
    this.listDistributionBoard();
    await this.listAllBranchOffices();
    await this.listAllProduts();
  },
  watch: {
    branchOfficeState() {
      this.updateOrigin(this.branchOfficeState.idBranchoffice);
    },
    action() {
      this.updateAction(this.action);
    },
  },
  components: {
    DistributionTableComponent,
    DistributionFormComponent,
    DistributionBranchFormComponent,
    DistributionProductsFormComponent,
    ButtonActionComponent,
  },
};
</script>
