<template>
  <div class="row">
    <div class="col-md-12 text-right pt-6">
      <button
        class="btn btn-outline-success"
        type="button"
        @click="confirm"
        :disabled="validator || sending"
      >
        <i class="feather icon-save mr-3"></i>{{ buttonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sending: {
      default: false,
    },
    buttonTitle: {
      default: "",
    },
    validator: { default: true },
  },
  methods: {
    confirm() {
      this.$emit("onClick");
    },
  },
};
</script>
