  <template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-12">
          <h6>
            <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
            Información del cuadro de distribución
          </h6>
        </div>
      </div>
      <div class="col-lg-12 col-xl-12 p-1 pt-6">
        <validation-observer ref="observer">
            <div class="row">
              <div class="col-md-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required|max:100"
                >
                  <v-text-field
                    v-model="model.name"
                    :counter="100"
                    :error-messages="errors"
                    label="Nombre*"
                    required
                    oninput="this.value = this.value.toUpperCase()"
                    maxlength="100"
                  />
                </validation-provider>
              </div>
              <div class="col-md-8">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required|max:200"
                >
                  <v-text-field
                    v-model="model.description"
                    :counter="200"
                    maxlength="200"
                    :error-messages="errors"
                    label="Descripción*"
                    oninput="this.value = this.value.toUpperCase()"
                    required
                    autocomplete="off"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    model: Object,
  },
  methods: {},
  components: {
    ValidationProvider,
    ValidationObserver,
  }
};
</script>